.noborder-button:focus {
  outline: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}


.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
    font-size: 12px;
    background: #0000FF;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px;
}

.black-cart {
    color: #000000;
   
}
.black-dot {
    color: #000000;
}
.dropdown-toggle::after {
    display: none;
    
}
